// Defaults to dark theme
:root {
  --background-color: #{darken($color-primary, 5%)};
  --text-color: white;
  --button-color: #{lighten($color-secondary-light, 7%)};
}

// White theme
:root.whitetheme {
  --background-color: white;
  --text-color: $color-primary-dark;
  --button-color: #{lighten($color-secondary-light, 7%)};
}

body, html {
  height: 100%;
  width: 100%;
}

body {
  background: var(--background-color);
}

main {
  color: var(--text-color);
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  h1 {
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 0;
  }

  p {
    font-weight: lighter;
    letter-spacing: 1px;
    font-size: 1.15rem;
  }

  .buttons {
    margin-top: 80px;

    display: flex;
    flex-direction: row;
  }
}


// "useful" MULTICOLOR STYLES
.multicolor {
  $c-u: green;
  $c-s: blue;
  $c-e: BurlyWood;
  $c-f: DarkMagenta;
  $c-u2: lime;
  $c-l: IndianRed;

  font-size: 1.7rem;

  padding: 4px;
  background-color: transparentize($color-primary-light, 0.95);

  span#u {
    color: $c-u;
  }
  span#s {
    color: $c-s;
  }
  span#e {
    color: $c-e;
  }
  span#f {
    color: $c-f;
  }
  span#u2 {
    color: $c-u2;
  }
  span#l {
    color: $c-l;
  }
}

// BUTTON STYLES
$shadow-size: 4px;
$shadow-blur: 0;
$shadow-spread: 1px;
$shadow-color: transparentize($color-primary-dark, 0.25);

@keyframes shake {
  0% { transform: skewX(-15deg); }
  5% { transform: skewX(15deg); }
  10% { transform: skewX(-15deg); }
  15% { transform: skewX(15deg); }
  20% { transform: skewX(0deg); }
  100% { transform: skewX(0deg); }
}


.button {
  // General styles
  position: relative;
  &:not(:first-of-type) {
    margin-left: 25px;
  }

  padding: 6px 10px;
  border-radius: 2px;

  color: $color-on-secondary;
  font-size: 1.5rem;
  font-weight: 700;

  cursor: pointer;
  user-select: none;

  background-color: var(--button-color);
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-spread $shadow-color;

  transition: .35s $ease-out-quint;

  // Specific button styles
  &#b1 {
    transform: scale(1);
  }
  &#b2 {
    animation: none;
  }
  &#b3 {

  }
}

.button:hover {
  // General styles

  // Specific button styles
  &#b1 {
    transform: scale(1.15) skewX(-7deg);
  }
  &#b2 {
    animation-name: shake;
    animation-duration: 1.35s;
    animation-delay: .15s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
  &#b3 {
    box-shadow: $shadow-size+2 $shadow-size+2 $shadow-blur $shadow-spread+1000 $shadow-color;
  }
}

// PHONE OPTIMIZATION
@include media("<=phone") {
  main {
    .buttons {
      flex-direction: column;
      align-items: center;
      margin: 5px 15px;
    }

    .button {
      &:not(:first-of-type) {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }
}
