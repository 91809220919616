/* Variables
 * ===============
 *
 * All variables and most of the configuration is defined on this page. */

/*
 * Breakpoints
 * Example: @include media('<phone') { font-size: 20px }
 */
$breakpoints: im-to-em((
  small-phone: 410px,
  phone: 768px,
  desktop: 1024px,
  large-desktop: 1700px
));

/*
 * COLORS
 */
// Base
$color-white: #fafafa;

// Primary
$color-primary: #424242;
$color-primary-light: #6d6d6d;
$color-primary-dark: #1b1b1b;
$color-on-primary: #ffffff;

// Secondary
$color-secondary: #303f9f;
$color-secondary-light: #666ad1;
$color-secondary-dark: #001970;
$color-on-secondary: #ffffff;

/*
 * FONT STACKS
 */
$font-family-primary: Montserrat, Helvetica, sans-serif;
$font-family-secondary: "LatoWeb", Arial, sans-serif;
