//----------------------------------*\
// SCSS MIXINS
//----------------------------------*/

// include-media module
@import "../../node_modules/include-media/dist/include-media";
// include-media plugin
@import "modules/_im-to-em.scss";

//----------------------------------*\
// CONFIG MODULES
//----------------------------------*/

// Config modules
@import "base/_vars.scss";

// Common animations
@import "modules/_animations.scss";
@import "modules/_clearfix.scss";
// Other resets
@import "modules/_defaults.scss";
// normalize.css
@import "modules/_normalize.scss";
// Formatting for print
@import "modules/_print.scss";
// Fonts
@import "modules/_typography.scss";

//----------------------------------*\
// LAYOUT
//----------------------------------*/
@import "layout/index";
@import "layout/switch";
