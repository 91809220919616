// Generic rounded toggle switch
$switch-width: 60px;
$switch-height: 34px;

.switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

// Specific to this one
#theme {
  position: absolute;

  top: 65px;
  right: 65px;

  .switch {
    position: relative;

    .label {
      $label-width: 125px;
      position: absolute;
      bottom: 0;
      top: 0;
      left: -$label-width;

      font-size: 0.9rem;
      height: $switch-height;
      width: $label-width;

      display: flex;
      justify-content: center;
      align-items: center;

      opacity: 0;
      transform: scale(0) rotate(3deg);
      transition: .45s $ease-out-quint;
    }
  }
}

#theme:hover {
  .switch {
    .label {
      opacity: 1;
      transform: scale(1.1) rotate(-3deg);
    }
  }
}
